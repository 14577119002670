import { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useFetchableEffect } from './useFetchableEffect';
import { useCallback } from 'react';
import { getExpressParcelLiveTrackingInfo } from '../utils/api';
import moment from 'moment-timezone';

const MOCK_MESSAGE = [
  {
    entity: [
      {
        id: 'device-MHMtun0458-101/be89484e-7be7-48eb-89fc-e45aedf33a59',
        trip_update: {
          trip: {
            route_id: '187888.458',
            schedule_relationship: 'SCHEDULED',
            start_date: '20230804',
            start_time: '11:30:00',
            trip_id: '205674.458',
          },
          vehicle: { id: 'device-MHMtun0458-101', label: '205674.458' },
          stop_time_update: [{ stop_sequence: 10, departure: { time: 1691138201 } }],
        },
      },
      {
        id: 'device-MHMtun0458-101/2aaf210b-b249-44f1-9fc4-1e161b9f87ba',
        vehicle: {
          congestion_level: 'RUNNING_SMOOTHLY',
          current_status: 'IN_TRANSIT_TO',
          current_stop_sequence: 10,
          position: {
            bearing: 61.087444,
            latitude: 63.82482,
            longitude: 23.083883,
            odometer: 3.8203375201672314,
            speed: 13.971676,
          },
          stop_id: '155403',
          timestamp: 1691138201,
          trip: {
            route_id: '187888.458',
            schedule_relationship: 'SCHEDULED',
            start_date: '20230804',
            start_time: '11:30:00',
            trip_id: '205674.458',
          },
          vehicle: { id: 'device-MHMtun0458-101', label: '205674.458' },
        },
      },
      {
        id: 'device-MHMtun0458-101/77594ee5-9053-43db-9cbd-66831221312b',
        trip: { trip_id: '205674.458' },
        trip_update: {
          stop_time_update: [{ stop_sequence: 11, arrival: { delay: -79 } }],
        },
      },
    ],
    header: {
      agency_id: '209',
      arrival_stop_name: 'Kokkola',
      departure_id: '0009',
      driver_id: '7038',
      kinesisDisplayName: '0009',
      line_id: '0823',
      line_number: '4/5',
      partner_code: '458',
      route_source: 'MINFO',
      start_stop_name: 'Kokkola',
      timestamp: 1691138201,
      workshift_id: '83123866-db0b-4376-b607-c83e0e2e2765',
    },
  },
];

export const useWebsocket = (url, onMessage) => {
  const [ws, setWS] = useState(null);
  useEffect(() => {
    if (!url) {
      setWS(null);
      return;
    }
    if (url.startsWith('#TODO#')) {
      onMessage(MOCK_MESSAGE);
      return;
    }
    const ws = new WebSocket(url);
    setWS(ws);
    ws.onmessage = event => onMessage(JSON.parse(event.data));
    return () => {
      ws.close();
    };
  }, [url, onMessage, setWS]);
  return ws;
};

export const useLiveTracking = (shipmentNumber, expressToken) => {
  const [trackingInfo, { error, loading }] = useFetchableEffect(async () => {
    const res = await getExpressParcelLiveTrackingInfo({ shipmentNumber, token: expressToken });
    return res;
  }, [shipmentNumber, expressToken]);
  const [info, setInfo] = useState({});
  const handleMessage = useCallback(
    data => {
      const content = data.find(it => it.entity);
      if (!content) return;
      const vehicleEntity = content.entity.find(it => it.vehicle);
      const tripEntity = content.entity.find(it => it.trip);
      setInfo(val => ({
        location: vehicleEntity?.vehicle?.position || val.location,
        delay: tripEntity?.trip_update?.stop_time_update?.[0]?.arrival?.delay ?? val.delay,
      }));
    },
    [setInfo]
  );
  useWebsocket(trackingInfo && trackingInfo.url, handleMessage);
  const estimatedArrivalTime = useMemo(() => {
    if (!trackingInfo?.estimatedArrivalTime) return null;
    const date = moment(trackingInfo?.estimatedArrivalTime).tz('Europe/Helsinki');
    return date.add(info.delay, 'seconds');
  }, [trackingInfo?.estimatedArrivalTime, info.delay]);
  return { ...(trackingInfo || {}), ...info, error, loading, estimatedArrivalTime };
};
