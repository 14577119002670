/** @jsx jsx */
import { jsx, Styled, Box, Flex } from 'theme-ui';
import { useMemo } from 'react';
import NotFoundPage from './404';
import { useLiveTracking } from '../hooks/live-tracking';
import Layout from '../components/layout';
import { useTranslate } from '../utils/getLanguage';
import { isBrowser } from '../utils';
import { Spinner } from '../components';
import LiveTrackingMap from '../components/express/LiveTrackingMap';
import moment from 'moment-timezone';

const LiveTrackingPage = props => {
  const { location: pageLocation, pageContext } = props;
  const paths = (pageContext && pageContext.paths) || {};
  const locale = (pageContext && pageContext.locale) || 'en';
  const translate = useTranslate();
  const shipmentNumber = isBrowser && new URLSearchParams(pageLocation?.search).get('shipmentNumber');
  const token = isBrowser && new URLSearchParams(pageLocation?.search).get('token');
  const {
    location,
    error,
    loading,
    estimatedArrivalTime,
    stopAreaName,
    placeName,
    sender,
    pickupCode,
  } = useLiveTracking(shipmentNumber, token);
  const minutesToArrival = useMemo(() => {
    if (!estimatedArrivalTime) return null;
    return estimatedArrivalTime.diff(moment(), 'minutes');
  }, [estimatedArrivalTime]);
  if (!shipmentNumber && !loading) {
    return <NotFoundPage {...props} />;
  }
  return (
    <Layout
      title={translate('delivery.express.liveTracking.title')}
      paths={paths}
      locale={locale}
      showAppBanner="paketit"
    >
      <Box sx={{ p: 4, textAlign: 'start' }}>
        <Styled.h1 sx={{ color: 'secondary', mb: 4, textAlign: 'start' }}>
          {translate('delivery.express.liveTracking.title')}
        </Styled.h1>
        {error && <p>{error}</p>}
        {loading && (
          <Box sx={{ position: 'relative' }}>
            <Spinner />
          </Box>
        )}
        {!error && !loading && (
          <>
            <Box sx={{ mb: 2, pt: 2 }}>
              <Box sx={{ pb: 2, borderBottom: '1px solid', borderBottomColor: 'border', textAlign: 'start' }}>
                {translate('delivery.express.liveTracking.subtitle', { shipmentNumber, sender })}
              </Box>
              {minutesToArrival != null && (
                <Box sx={{ mt: 2 }}>
                  {translate('delivery.express.liveTracking.estimatedTime', {
                    minutesToArrival,
                    timeOfArrival: estimatedArrivalTime.format('HH.mm'),
                    stopName: `${placeName} (${stopAreaName})`,
                  })}
                </Box>
              )}
            </Box>
            {pickupCode && (
              <Flex sx={{ alignItems: 'baseline', mt: 2, mb: 4 }}>
                <Box sx={{ color: 'grayLight', mr: 5 }}>{translate('delivery.express.liveTracking.pickupCode')}</Box>
                <Box sx={{ fontSize: 4 }}>{pickupCode}</Box>
              </Flex>
            )}
            <LiveTrackingMap location={location} height={800} />
          </>
        )}
      </Box>
    </Layout>
  );
};

export default LiveTrackingPage;
