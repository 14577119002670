/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import HereMap from '../HereMap';
import { useMapMarker } from '../../home-delivery/Map';

const FOCUS_POINT_MARKER = `<svg width="50px" height="26px" viewBox="0 0 50 26" xmlns="http://www.w3.org/2000/svg">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
    <g transform="translate(2, 1)" fill="#001E60" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="1.276">
        <ellipse id="Oval" cx="38.4066986" cy="20.587156" rx="3.41148325" ry="3.41284404"></ellipse>
        <ellipse id="Oval" cx="7.70334928" cy="20.587156" rx="3.41148325" ry="3.41284404"></ellipse>
        <path d="M3.5215311,0 L42.4784689,0 C44.4593301,0 46,1.5412844 46,3.52293578 L46,17.3944954 C46,19.3761468 44.4593301,20.9174312 42.4784689,20.9174312 L41.8181818,20.9174312 C41.8181818,20.8073394 41.8181818,20.6972477 41.8181818,20.587156 C41.8181818,18.7155963 40.277512,17.1743119 38.4066986,17.1743119 C36.5358852,17.1743119 34.9952153,18.7155963 34.9952153,20.587156 C34.9952153,20.6972477 34.9952153,20.8073394 34.9952153,20.9174312 L11.1148325,20.9174312 C11.1148325,20.8073394 11.1148325,20.6972477 11.1148325,20.587156 C11.1148325,18.7155963 9.57416268,17.1743119 7.70334928,17.1743119 C5.83253589,17.1743119 4.29186603,18.7155963 4.29186603,20.587156 C4.29186603,20.6972477 4.29186603,20.8073394 4.29186603,20.9174312 L3.5215311,20.9174312 C1.54066986,20.9174312 0,19.3761468 0,17.3944954 L0,3.63302752 C0,1.65137615 1.54066986,0 3.5215311,0 L3.5215311,0 Z" id="Path"></path>
        <path d="M15.5167464,13.3211009 L5.3923445,13.3211009 C4.07177033,13.3211009 3.08133971,12.2201835 3.08133971,11.0091743 L3.08133971,6.05504587 C3.08133971,4.73394495 4.18181818,3.74311927 5.3923445,3.74311927 L15.5167464,3.74311927 C16.8373206,3.74311927 17.8277512,4.8440367 17.8277512,6.05504587 L17.8277512,11.0091743 C17.937799,12.2201835 16.8373206,13.3211009 15.5167464,13.3211009 Z" id="Path"></path>
        <path d="M33.3444976,13.3211009 L23.2200957,13.3211009 C21.8995215,13.3211009 20.9090909,12.2201835 20.9090909,11.0091743 L20.9090909,6.05504587 C20.9090909,4.73394495 22.0095694,3.74311927 23.2200957,3.74311927 L33.3444976,3.74311927 C34.6650718,3.74311927 35.6555024,4.8440367 35.6555024,6.05504587 L35.6555024,11.0091743 C35.7655502,12.2201835 34.6650718,13.3211009 33.3444976,13.3211009 Z" id="Path"></path>
        <path d="M46,3.52293578 L40.8277512,3.52293578 C39.7272727,3.52293578 38.9569378,4.40366972 38.9569378,5.39449541 L38.9569378,11.3394495 C38.9569378,12.440367 39.8373206,13.2110092 40.8277512,13.2110092 L46,13.2110092" id="Path"></path>
    </g>
</g>
</svg>`;

export default ({ location, height }) => {
  const [mapRef, setMapRef] = useState(null);
  const HERE = useRef();
  const handleMapCreated = useCallback(
    (newMap, _platform, newHERE) => {
      HERE.current = newHERE;
      setMapRef(newMap);
    },
    [setMapRef]
  );

  useMapMarker(
    HERE.current,
    mapRef,
    location ? { latitude: location.latitude, longitude: location.longitude } : null,
    FOCUS_POINT_MARKER,
    true,
    {
      anchor: { x: 10, y: 10 },
      size: { w: 20, h: 20 },
    }
  );

  useEffect(() => {
    if (!mapRef || !location) {
      return;
    }
    mapRef.getViewModel().setLookAtData(
      {
        position: { lat: location.latitude, lng: location.longitude },
        zoom: 12,
      },
      false
    );
  }, [mapRef, location]);

  return (
    <Box sx={{ width: '100%', height }}>
      <HereMap onMapCreated={handleMapCreated} height={`${height}px`} paddingLeft={0} absoluteHeight />
    </Box>
  );
};
