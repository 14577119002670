/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import Layout from '../components/layout';
import Container from '../components/Container';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import * as analytics from '../utils/analytics';

const NotFoundPage = ({ pageContext, location }) => {
  analytics.usePageCategory('not_set');
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);

  return (
    <Layout
      title={translate('404.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <Container sx={{ textAlign: 'center', py: [5] }}>
        <Styled.h1>{translate('404.title')}</Styled.h1>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
