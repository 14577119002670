import { useCallback, useState, useEffect } from 'react';

export function useAsyncCallback(func, dependencies) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const callback = useCallback(
    async (...args) => {
      let res;
      try {
        setError(null);
        setLoading(true);
        res = await func(...args);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
      return res;
    },
    [...dependencies, setLoading, setError]
  );
  return [callback, loading, error];
}

export function useFetchableEffect(func, dependencies, defaultValue) {
  const [state, setState] = useState(defaultValue);
  const [callback, loading, error] = useAsyncCallback(func, dependencies);
  useEffect(() => {
    callback().then(res => setState(res));
  }, [...dependencies, callback, setState]);
  return [state, { loading, error, callback }];
}
